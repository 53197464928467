@import "../../sass/responsive.scss";
.m-phone {
  margin-top: 12em;
  color: white;
  text-align: center;

  p {
    margin-top: 2em;
  }
  .input-p {
    background-color: transparent;
    border: 2px solid #e0e0e030;
    color: white;
    border-radius: 10px;
    margin-bottom: 10px;
    width: 20%;
    height: 35px;
    text-align: center;
    @include respond(phone) {
      width: 68%;
    }
  }

  .btn-siguiente-p {
    margin-top: 14em;
    border-radius: 10px;

    width: 10em;
    padding: 2px;
    font-size: 16px;

    cursor: pointer;

    background: linear-gradient(180deg, #0a6dc9ec, #021738c5);
    border: 1px solid #000000;
    color: white;
  }
}
