@import "../../../sass/responsive.scss";


.txts-back-cedula {
  text-align: center;
  .txt {
    font-size: 18px;
    color: white;
    font-family: Arial;
    font-weight: 700;
    opacity: 0.7;
  }

  .txt_resalt {
    font-size: 26px;
    color: white;
    font-family: Arial;
    font-weight: 700;
    opacity: 0.9;
  }
}
.txts {
  text-align: center;

  .txt {
    font-size: 18px;
    color: white;
    font-family: Arial;
    font-weight: 700;
    opacity: 0.7;
  }

  .txt_resalt {
    font-size: 26px;
    color: white;
    font-family: Arial;
    font-weight: 700;
    opacity: 0.9;
  }
  .img_1 {
    width: 20%;
    z-index: 2;
  }
  .img_2 {
    width: 40%;
    z-index: 2;
  }
}
.color1 {
  display: flex;
  .txt {
    font-size: 20px;
    color: white;
    font-family: Arial;
    font-weight: 400;
    opacity: 0.9;
  }
  text-align: center;
  // transform: rotate(360deg);
  margin-top: 20%;
  @include respond(phone) {
    text-align: center;
    // transform: rotate(90deg);
    // margin-top: 10.3em !important;
  }
}
.color2 {
  display: flex;
  .txt {
    font-size: 20px;
    color: white;
    font-family: Arial;
    font-weight: 400;
    opacity: 0.9;
  }
  text-align: center;
  // transform: rotate(360deg);
  margin-top: 20%;
  @include respond(phone) {
    text-align: center;
    // transform: rotate(90deg);
    // margin-top: 10.3em !important;
  }
}
.color3 {
  display: flex;
  .txt {
    font-size: 20px;
    color: white;
    font-family: Arial;
    font-weight: 400;
    opacity: 0.9;
  }
  margin-top: 20%;
  text-align: center;
  // transform: rotate(360deg);
  @include respond(phone) {
    text-align: center;
    // transform: rotate(90deg);
    // margin-top: 10em !important;
  }
}

.square {
  width: 75vw;
  height: 73%;
}
.txt-face {
  // margin-top: 18% !important;
  text-align: center;
  // transform: rotate(360deg);
  .icon_face {
    text-align: center;
    width: 30%;
    @include respond(phone) {
      margin-top: 1em;
      margin-bottom: 10%;
    }
  }
  .txtf {
    font-size: 24px;
    color: white;
    font-family: Arial;
    font-weight: 700;
    opacity: 0.7;
  }
  .txtf1 {
    margin-top: 10px;
    font-size: 18px;
    color: white;
    font-family: Arial;
    font-weight: 700;
    opacity: 0.7;
  }
  .txtf2 {
    font-size: 24px;
    color: white;
    font-family: Arial;
    font-weight: 700;
    opacity: 0.7;
  }
}
.txt-voice {
  // margin-top: 20% !important;
  text-align: center;
  // transform: rotate(360deg);
  .txtv {
    font-size: 28px;
    color: white;
    font-family: Arial;
    font-weight: 700;
    opacity: 0.7;
  }
}
//landscape: estilos para rotacion de pantalla horizontal
@media (orientation: landscape) {
  .txts {
    margin-top: 2.5em !important;
    .img_2 {
      width: 30%;
      z-index: 2;
    }
  }
  .color1 {
    margin-top: 6em !important;
    transform: rotate(-360deg);
  }
  .color2 {
    margin-top: 6em !important;
    transform: rotate(-360deg);
  }
  .color3 {
    transform: rotate(-360deg);
    margin-top: 8em !important;
  }

  .txt-face {
    // margin-top: 1em;
    transform: rotate(-90deg);
  }
}
//portrait: estilos para rotacion de pantalla vertical
@media (orientation: portrait) {
  .txts {
    margin-top: 12em !important;
    transform: rotate(90deg);
  }
  .txts-back-cedula {
    margin-top: 16em !important;
    transform: rotate(90deg);
  }
  .color1 {
    margin-top: 10.4em !important;
    // margin-top: 1em !important;
    transform: rotate(90deg);
  }
  .color2 {
    margin-top: 10.4em !important;
    // margin-top: 1em !important;
    transform: rotate(90deg);
  }
  .color3 {
    margin-top: 13em !important;
    transform: rotate(90deg);
  }
  .txt-face {
    margin-top: 0em;
  }
}
.inner-face {
  .inner-txt2-face {
    padding: 1%;

    .icon-glasses {
      width: 80px;
    }
    p {
      margin-top: 5%;
      font-size: 24px;
      line-height: 19px;
      text-align: right;

      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
  }
}
