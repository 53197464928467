.loader{
    text-align:center;
p{
  font-size: 24px;
  margin-top: 1%;
  color: white;
}
    img{
        margin-top: 70%;
        animation: rotation 2s infinite linear;
    }
    .rotate {
        animation: rotation 2s infinite linear;
      }
      @keyframes rotation {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(359deg);
        }
      }
}