@import "../../sass/responsive.scss";

.terms-conditions {
  padding: 10%;
  .text-intro {
    margin-top: 10%;
    h1 {
      padding: 0%;
      @include respond(phone) {
        padding: 0% 1% 0% 1%;
      }
    }

    @include respond(phone) {
      margin-top: 35%;
    }
  }

  .row_icon {
    display: block;
    .row_col {
      width: 50%;
      margin-bottom: 0%;
      @include respond(phone) {
        margin-bottom: 10%;
      }
    }
    @include respond(phone) {
      display: contents;
    }

    .icon_principal {
      width: 80%;
      @include respond(phone) {
        width: 60%;
      }
    }
    p {
      font-size: 11px;
      @include respond(phone) {
        font-size: 11px;
      }
    }
  }

  .container_terminos {
    .instructions {
      float: right;
      width: 42%;

      @include respond(phone) {
        width: 100%;
      }

      .row {
        margin-bottom: 2vh;
      }
      .instruction_icon {
        img {
          width: 40px;
          @include respond(phone) {
            width: 35px;
          }
        }
        // align-self: center;
        margin: 2px;
        @include respond(phone) {
          margin: 1px;
        }
      }
      .instruction_text {
        text-align: left;
        font-size: 15px;
        @include respond(phone) {
          text-align: left;
          font-size: 11px;
        }

        // text-align: justify;
      }
    }
  }

  .container_accepterms {
    margin-top: 4%;
    width: 50%;

    @include respond(phone) {
      margin-top: 13%;
      width: 100%;
    }

    .checkbox_accepterms {
      margin-left: 50%;

      @include respond(phone) {
        margin: 5px;
      }
    }
    .col_accepterms {
      .text-terms {
        display: table-footer-group;
        color: #ffffff63;
        font-size: 11px;
      }

    }
    .row-check {
      display: flex;
      .check-msg {
        color: #ffffff;
        margin-top: 3%;

        p {
          font-size: 9px;
          text-align: right;
        }
      }
      .check-msg-img {
        width: 40%;
        align-self: center;
        img {
          width: 70%;
        }
      }
    }
  }

  .conatiner-buttons {
    margin-top: 4%;
    @include respond(phone) {
      margin-top: 13%;
    }
    button.btn.btn-default.deniet {
      border: 1px solid rgb(156, 155, 155);
      color: rgb(158, 157, 157);
      font-size: 18px;
      margin-right: 8%;
      @include respond(phone) {
        margin-right: 8%;
      }
    }
    .btn:hover {
      color: #d3dde6;
      text-decoration: none;
    }
    button.btn.btn-default.acept {
      font-size: 16px;
      // color: #d3dde6;
      // cursor: pointer;
      // margin-left: 8%;
      cursor: pointer;

      background: linear-gradient(180deg, #0a6dc9ec, #021738c5);
      border: 1px solid #000000;
      color: white;
      padding: 8px;

      @include respond(phone) {
        margin-left: 8%;
      }
    }
  }
}
