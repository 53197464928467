@import "../../sass/responsive.scss";
.m-email {
  margin-top: 9em;
  color: white;
  text-align: center;
  .icon-email {
    color: white;
    font-size: 100px;
  }
  p {
    margin-top: 2em;
  }
  .input-e {
    background-color: transparent;
    border: 2px solid #e0e0e030;
    color: white;
    border-radius: 10px;
    margin-bottom: 10px;
    width: 20%;
    height: 35px;
    text-align: center;
    @include respond(phone) {
      width: 68%;
    }
  }
  .btn-siguiente {
    margin-top: 10em;
    border-radius: 10px;

    width: 10em;
    padding: 2px;
    font-size: 16px;

    cursor: pointer;

    background: linear-gradient(180deg, #0a6dc9ec, #021738c5);
    border: 1px solid #000000;
    color: white;
  }
}
