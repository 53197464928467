@import "../../sass/responsive.scss";

.container_instruccionGeo{
  padding: 8%;
// margin-top: 6%;
text-align: -webkit-center;
.text_1{
  padding: 0% 14% 2% 14%;
  font-size:20px;
  color: 	#ffffff;
  font-weight: bold;
  text-shadow: 0px 1px 1px #000000;
  @include respond(phone) {
    padding: 0% 14% 2% 14%;
  }

}
.text_2{

  font-size:20px;
  font-weight: 600;

  
}
.icon-e{
  color: white; 
  font-size: 100px;
}

img{
  width: 15%;
  @include respond(phone) {
    width: 30%;
  }
}

.text_3{
  text-shadow: 0px 1px 1px #000000;
  font-size:20px;
  font-weight: 600;
  display: grid;
  padding: 2% 32% 0% 32%;
  @include respond(phone) {
    padding: 3% 6.5% 0% 6.5%;
  }
  
}
.text_4{
  color: rgba(228, 228, 228, 0.507);
  font-size:15px;
  font-weight: 600;
  display: grid;
  padding: 0% 38% 0% 38%;
  @include respond(phone) {
    padding: 2% 4% 3% 4%;
  }
}

.btn_modify{

  border-radius: 6px;
  background: transparent;
 
  border: 1px solid #9b9a9ac4;
  color:  #9b9a9ac4;
  width: 12em;
  padding: 2px;

  h1 {
    margin-top: 2px;
    font-weight: 700;
    font-size: 15px;
  }
}


.btn_continue{
  margin-top: 8%;
  border-radius: 10px;

  width: 13em;
  padding: 2px;
  font-size: 16px;

  cursor: pointer;

  background: linear-gradient(180deg, #0a6dc9ec, #021738c5);
  border: 1px solid #000000;
  color: white;
  @include respond(phone) {
    margin-top: 10%;
  }
  h1 {
    margin-top: 2px;
    font-weight: 700;
    font-size: 19px;
  }
}

.text_5{
  margin-top:3%;
  color: rgba(228, 227, 227, 0.541);
  font-size:10px;
  font-weight: 600;
  display: grid;
  @include respond(phone) {
    margin-top:8%;
  }
  
}

}
// ---------------css of Recolectverify2 and Recolectverify-----------------------
/* .instruccion-geo {
  .otp-inputs-code {
    display: inline-grid;
    .inputs-code {
      width: 45px !important;
      height: 60px;
      margin: 2px;
      border: none;
      background-color: #e0e0e030;
      color: white;
      border-radius: 10px;
      margin-bottom: 20px;

      @include respond(tab-land) {
        margin: 2px;
        width: 35px !important;
        height: 46px !important;
      }
    }
  }
} */
