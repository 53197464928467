.btn-continue {
    margin-top: 1em;
  font-size: 16px;
  cursor: pointer;
  border-radius: 6px;
  background: linear-gradient(180deg, #0a6dc9ec, #021738c5);
  border: 1px solid #000000;
  color: white;
  padding: 8px;
}
