.button {
  border-radius: 7px;
  color: white;
  font-size: 18px;
  font-weight: bold;
  background: linear-gradient(180deg, #0a6dc9ec, #021738c5);
 border: 1px solid #000000d3;
}
.button1{
  margin-bottom:2%;
  color: #C4C4C4;
  font-size: 16px;
  font-weight: bold;
  border: 1px solid #C4C4C4;
  background-color: transparent;
  border-radius: 7px;
}