@import "../../sass/responsive.scss";
.introPage {
 
  // filter: linear-gradient(to bottom,  #0066FE, #4B9EEA);
  // background-size: 400% 400%;
  // contain: paint;
 

  .first_text {
   
    margin-top: 2%;
    @include respond(phone) {
      margin-top: 35%;
    }
    h1 {
      font-size: 56px !important;
      font-weight: bold;
      @include respond(phone) {
        font-size: 50px !important;
      }
    }
  }
  .second_text {
    margin-top: 2%;
    @include respond(phone) {
      margin-top: 35%;
    }

    h1 {
      font-weight: 500;
      font-size: 28px !important;
      padding: 0%;
      padding: 0%;
      @include respond(phone) {
        padding: 2% 12% 0% 12%;
        font-size: 24px !important;
      }
    }
  }

  .third_text {
    color: white;
     margin-top: 8%;
    @include respond(phone) {
       margin-top: 40%;
    }
    .col1 {
      img {
        width: 10%;
        @include respond(phone) {
          width: 20%;
        }
      }
      h1 {
        font-weight: bold;
        padding: 0% 45px 0% 45px;
        font-size: 22px;

        @include respond(phone) {
          padding: 0% 45px 0% 45px;
        }
      }
    }

    .col2 {
margin-top: 20%;
      img {
        width: 10%;
        @include respond(phone) {
          width: 20%;
        }
      }
      h1 {
        font-weight: bold;
        padding: 0% 18% 0% 18%;
        font-size: 22px;
        @include respond(phone) {
          padding: 0% 18% 0% 18%;
        }
      }
    }
    .btn_next {
      margin-top: 8%;
      border-radius: 10px;
      background: rgb(51, 45, 110);
      background: linear-gradient(
        180deg,
        rgba(51, 45, 110, 1) 0%,
        rgba(32, 28, 72, 1) 35%
      );
      border: 1px solid #000000;
      color: white;
      width: 13em;
      padding: 2px;

      @include respond(phone) {
        margin-top: 14%;
      }
      h1 {
        font-weight: 700;
        margin-top: 7px;
        font-size: 15px;
      }
    }
  }
}

.fourth_text {
  color: white;
  margin-top: 9%;
  @include respond(phone) {
    margin-top: 28%;
  }
  img {
    width: 20%;
    @include respond(phone) {
      width: 30%;
    }
  }
  h1 {
    font-weight: bold;
    padding: 2% 10% 0% 10%;
    font-size: 25px;
    @include respond(phone) {
      padding: 6% 8% 0% 8%;
      font-size: 23px;
    }
  }

  
}
