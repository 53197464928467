@import "../../sass/responsive.scss";
.video-style {
  .styles-cedula {
    margin-top: 5vh;
    margin-right: 1em;
    .div-right-cedula {
      margin-top: 2em;
      p {
        font-size: 24px;
        color: white;
        font-family: Arial;
        font-weight: 700;
        opacity: 0.9;

        @media (orientation: portrait) {
          margin-top: 7em !important;
          transform: rotate(90deg);
        }
      }
      .img-content {
        text-align-last: center;
        img {
          @media (orientation: portrait) {
            margin-top: 2em !important;
            transform: rotate(90deg);
          }
        }
      }
    }
  }
}
