@import "../sass/responsive.scss";

// --------style old texts video --------------
/* .txts {
  margin-top: 20% !important;
  text-align: center;
  transform: rotate(360deg);


  .txt {
    font-size: 28px;
    color: white;
    font-family: Arial;
    font-weight: 700;
    opacity: 0.7;
  }
  .img_1 {
    width: 20%;
    z-index: 2;
  }
  .img_2 {
    width: 20%;
    z-index: 2;
  }
}

.color2 {
  .txt {
    font-size: 28px;
    color: white;
    font-family: Arial;
    font-weight: 400;
    opacity: 0.7;
  }
  text-align: center;
  transform: rotate(360deg);
  margin-top: 20%;
  @include respond(phone) {
    text-align: center;
    transform: rotate(90deg);
    margin-top: 10.3em !important;
  }
}
.color3 {
  .txt {
    font-size: 28px;
    color: white;
    font-family: Arial;
    font-weight: 400;
    opacity: 0.7;
  }
  margin-top: 20%;
  text-align: center;
  transform: rotate(360deg);
  @include respond(phone) {
    text-align: center;
    transform: rotate(90deg);
    margin-top: 9em !important;
  }
}
 */
// .square {
//   width: 75vw;
//   height: 73%;
//   @include respond(tab-land) {
//   }
// }
.MyLoader_spinneroverlay {
  margin-top: 20%;
  @include respond(tab-land) {
    margin-top: 60%;
  }
}
.MyLoader_overlay {
  margin-top: 10%;
  background-image: url("https://mdn.mozillademos.org/files/6457/mdn_logo_only_color.png");
  @include respond(phone) {
    margin-top: 40%;
  }
}
