@import "../../sass/responsive.scss";
.advices {
  margin-top: 7em;
  h1 {
    line-height: 0.9;
    font-size: 28px;
  }
  .advice-title {
    font-size: 28px;
  }

  .advice1 {
    margin-top: 16%;
    .img1 {
      transform: rotate(10deg);
      width: 133.99px;
      height: 97.39px;
      margin-bottom: 14%;
    }
    margin-bottom: 4em;
  }
}
.advices2 {
  margin-top: 5em;
  .advices2-txt {
    margin-top: 3em;
    margin-bottom: 3em;
    h1 {
      line-height: 0.9;
      font-size: 24px;
    }
  }
}
.advices3 {
  margin-top: 4em;
  .advices3-txt {
    margin-top: 3em;
    margin-bottom: 3.5em;
    h1 {
      line-height: 0.9;
      font-size: 24px;
    }
    h2 {
      line-height: 0.9;
      font-size: 18px;
    }
  }
}
