@import "../../sass/responsive.scss";
.recolectStart {
  color: white;
  text-align: center;
padding:8%;
  // margin-top: 8%;
  @include respond(phone) {
    // margin-top: 10%;
    padding:6%;
  }
  .txt_enter {
    margin-top: 3%;
    font-size: 21px;
    @include respond(phone) {
      margin-top: 7%;
    }
  }
  .txt_enter2 {
  margin:2%;
    font-size: 18px;
    margin-bottom:8%;

  }
  .input-form {
    border: none;
    background-color: transparent;
    border: 2px solid #e0e0e030;
    color: white;
    border-radius: 10px;
    margin-bottom: 10px;
    width: 20%;
    height: 35px;
    text-align: center;
    @include respond(phone) {
      width: 58%;
    }
  }

  .select-input {
    border: none;
    background-color: transparent;
    border: 2px solid #e0e0e030;
    color: white;
    border-radius: 10px;
    margin-bottom: 20px;
    width: 20%;
    text-align-last: center;
    height: 35px;
    @include respond(phone) {
      width: 58%;
    }

    .option-select {
      background-color: #e0e0e030 !important;
      // color: rgb(248, 244, 244) !important;
      color: black !important;
    }
  }

  .btn_next {
    margin-top: 8%;
    border-radius: 10px;

    width: 13em;
    padding: 2px;
    font-size: 16px;

    cursor: pointer;

    background: linear-gradient(180deg, #0a6dc9ec, #021738c5);
    border: 1px solid #000000;
    color: white;

    @include respond(phone) {
      margin-top: 5%;
    }
    h1 {
      margin-top: 2px;
      font-weight: 700;
      font-size: 15px;
    }
  }
}
